  

.login-page {
    display: flex;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  
 .left-pane,
.right-pane {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-bg-color);
}


.right-pane {
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  
}
  
.right-pane h2 {
  margin-bottom: 2vh;
}
  
 
  .image-container {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .image-container img {
    width: 100%;
    height: 100%; 
    object-fit: cover; 
  }
  
  .overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: var(--overlay-text-color);
    
  }
  
  .overlay h1 {
    font-size: 6vh;
    margin-bottom: 1vh;
  }
  
  .overlay p {
    font-size: 2vh;
    margin-bottom: 2vh;
  }
  
  .login-header {

    display: grid;
    align-content: center;
    font-size: 3vh;
    font-weight: 700; 
    background-color: var(--header-color);
    height: 9vh;
    color: var(--text-color);
  }
  .LoginMatCard {
    padding: 0 !important;
    width: 70%;
    height: auto;
    /* max-width: 600px; */
    /* width: 50vw; */
}


  